import styled from 'styled-components';
import Image from 'next/legacy/image';
import { mqUntil } from 'styles/styles';
import { PageItemImagesParamsProps } from 'r2d2';

export const Images: React.FC<PageItemImagesParamsProps> = ({ images = [], cssClassName = '' }) =>
  images?.length ? (
    <StyledImages className={`pageitem images row ${cssClassName}`}>
      {images.map((image, index) => (
        <div className={`image image-${index + 1}`} key={image._id}>
          <Image
            className="image"
            src={image.src}
            width={image.width}
            height={image.height}
            layout="intrinsic"
            alt={image.alt || ''}
          />
        </div>
      ))}
    </StyledImages>
  ) : null;

const StyledImages = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 0 var(--side-padding);
  img {
    max-width: 100%;
  }
  ${mqUntil.desktop} {
    flex-direction: column;
  }
`;

export default Images;
