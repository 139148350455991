import styled from 'styled-components';
import classNames from 'classnames';
import Image from 'next/legacy/image';
import BuySection from 'components/product/buy-section';
import { FullProductForPage, PageItemSplashProductParamsProps } from 'r2d2';

export const SplashProduct: React.FC<PageItemSplashProductParamsProps> = ({ product, cssClassName = '' }) => {
  const { banner, inStock, artist, name, subText, images, description } = product;

  return (
    <StyledSplashProduct
      className={classNames('pageitem splash-product row', cssClassName, {
        'sold-out': !inStock,
      })}
    >
      <div className="inner">
        <div className="image-wrapper">
          {banner && (
            <div className="banner">
              <div className="inner">{banner}</div>
            </div>
          )}
          <Image
            src={images[0].src}
            width={images[0].width}
            height={images[0].height}
            layout="responsive"
            sizes="800px"
            alt={images[0].alt || ''}
          />
          <div className="image-overlay" />
        </div>
        <div className="info">
          {artist?.name && <h2 className="artist h3 display">{artist.name}</h2>}
          <h1 className="name">{name}</h1>
          {subText && <h2 className="subtext">{subText}</h2>}
          <div className="description" dangerouslySetInnerHTML={{ __html: description }} />
        </div>
        <BuySection product={product as FullProductForPage} />
      </div>
    </StyledSplashProduct>
  );
};

const StyledSplashProduct = styled.div`
  padding: 0 var(--side-padding);
  display: flex;
  margin: 2rem 0;
  justify-content: center;
  color: var(--base-foreground);

  .inner {
    width: 100%;
    max-width: 800px;
    position: relative;
  }

  &.sold-out {
    > div:not(.banner) {
      opacity: 0.3;
    }
  }

  .image-wrapper {
    position: relative;
  }

  .banner {
    z-index: 10;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 200px;
    height: 200px;
    overflow: hidden;
    pointer-events: none;

    .inner {
      text-align: center;
      background: var(--primary-background);
      color: var(--primary-foreground);
      transform: rotate(-45deg);
      text-transform: uppercase;
      font-weight: 800;
      padding: 0.5rem 1rem;
      position: absolute;
      width: 200px;
      top: 40px;
      left: -40px;
    }
  }

  .images {
    position: relative;
  }

  hr {
    display: none;
  }
`;

export default SplashProduct;
