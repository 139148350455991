import styled from 'styled-components';
import React from 'react';
import { PageItemHtmlParamsProps } from 'r2d2';

export const Html: React.FC<PageItemHtmlParamsProps> = ({ cssClassName = '', body }) =>
  body ? (
    <StyledHtml className={`pageitem html row ${cssClassName}`} dangerouslySetInnerHTML={{ __html: body }} />
  ) : null;

const StyledHtml = styled.div`
  padding: 0 var(--side-padding);
`;

export default Html;
