/* eslint-disable @next/next/no-img-element */
import { PageItemInstagramFeedParamsProps } from 'r2d2';
import styled from 'styled-components';
import { getBoxStyles } from 'styles/styles';

type StyledProps = {
  items: number;
};

const InstagramFeed: React.FC<PageItemInstagramFeedParamsProps> = ({ header, feed }) => {
  if (!feed?.length) {
    return null;
  }
  return (
    <StyledInstagramFeed className="pageitem instagram-feed" items={feed.length}>
      {header && <h2 className="display">{header}</h2>}
      <div className="feed">
        {feed.map((img) => (
          <div key={img.id} className="feed-item">
            <img src={img.src} alt={img.caption} />
            {img.caption && <div className="caption">{img.caption}</div>}
          </div>
        ))}
      </div>
    </StyledInstagramFeed>
  );
};

const StyledInstagramFeed = styled.section<StyledProps>`
  padding: 2rem var(--side-padding);

  .feed {
    ${(props) => getBoxStyles(props.items)}

    .feed-item {
      position: relative;
      img {
        width: 100%;
      }
      .caption {
        background: rgba(0, 0, 0, 0.4);
        color: white;
        text-align: center;
        padding: 0.5rem 1rem;
        position: absolute;
        bottom: 4rem;
        width: 100%;

        display: none;
      }
    }
  }
`;

export default InstagramFeed;
