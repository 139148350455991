import styled from 'styled-components';
import Embed from 'components/core/embed';
import React from 'react';
import { PageItemVideoEParamsProps } from 'r2d2';

export type VideoEmbedProps = PageItemVideoEParamsProps;
export const VideoEmbed: React.FC<VideoEmbedProps> = ({ link, header, body }) => (
  <StyledVideoEmbed className="pageitem images row">
    {header && <h2 className="display">{header}</h2>}
    {body && <p>{body}</p>}
    <Embed url={link} />
  </StyledVideoEmbed>
);

const StyledVideoEmbed = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 var(--side-padding);
`;

export default VideoEmbed;
