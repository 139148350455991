import Head from 'next/head';
import { useRouter } from 'next/router';
import { GetStaticProps } from 'next';
import { ParsedUrlQuery } from 'querystring';
import { buildImgixUrl } from 'utils/helpers';
import Breadcrumb from 'components/core/breadcrumb';
import Layout from './master-layout';
import React, { useEffect, useState } from 'react';
import LoadingPage from './loading-page';
import PageNotFound from './404-page';
import { PageStyles } from 'styles/styles';
import PreviewModeButton from 'components/core/preview-mode-button';
import type { Shop, SimplePage, FullPage, PageItem, Locales, ShopResponse } from 'r2d2';
import Products from 'components/pageitems/products';
import Quicklinks from 'components/pageitems/quicklinks';
import Banner from 'components/pageitems/banner';
import HeaderItem from 'components/pageitems/header-item';
import RichTextContent from 'components/pageitems/richtext-content';
import InstagramFeed from 'components/pageitems/instagram-feed';
import CountdownItem from 'components/pageitems/countdown';
import PasswordProtectionItem from 'components/pageitems/password-protection-item';
import Buttons from 'components/pageitems/buttons';
import Html from 'components/pageitems/html';
import Images from 'components/pageitems/images';
import VideoEmbed from 'components/pageitems/video-embed';
import SplashProduct from 'components/pageitems/splash-product';
import Newsletter from 'components/pageitems/newsletter';
import { getUpdatedPage } from 'data/page';

export type RegularPageProps = {
  shop: Shop;
  pages: SimplePage[];
  page: FullPage;
  preview: boolean;
};

export const RegularPage: React.FC<RegularPageProps> = ({ page, shop, preview }) => {
  const router = useRouter();

  const [pageItems, setPageItems] = useState<PageItem[] | null>(null);

  const updateWithPass = async (passwordHash: string): Promise<void> => {
    if (passwordHash) {
      const updatedPageData = await getUpdatedPage({
        path: router.asPath.split('?')[0],
        locale: router.locale! as Locales,
        passwordHash,
      });

      console.log('page', page);

      if (updatedPageData.success) {
        const updatedPage = updatedPageData.data;
        setPageItems(updatedPage.page.items);
      }
    }
  };

  useEffect(() => {
    setPageItems(null);
  }, [router.asPath]);

  if (router.isFallback) {
    return <LoadingPage cssClassName="page fallback" textDictKey="loadingRegularPage" />;
  }

  if (!page) {
    return <PageNotFound />;
  }
  const { domain } = shop;
  const path = router.asPath.split('?')[0];

  const currentFullUrl = `https://${domain}${path !== '/' ? path : ''}`;
  return (
    <Layout cssClassName={`page ${page.settings?.cssClassName || ''}`} empty={page.settings?.splashPage || false}>
      <Head>
        <title>{page.metaTitle}</title>
        <meta name="description" content={page.metaDescription} />
        <meta name="keywords" content={page.metaKeywords} />
        <meta property="og:title" content={page.metaTitle} />
        <meta property="og:url" content={currentFullUrl} />
        {page.metaImage && (
          <meta
            property="og:image"
            content={buildImgixUrl(page.metaImage.src, {
              w: 1200,
              h: 630,
              fit: 'crop',
              crop: 'faces',
            })}
          />
        )}
        <meta name="twitter:card" content="product" />
        <meta name="twitter:title" content={page.metaTitle} />
        <meta name="twitter:description" content={page.metaDescription} />
        {page.metaImage && (
          <meta
            property="twitter:image"
            content={buildImgixUrl(page.metaImage.src, {
              w: 1200,
              h: 630,
              fit: 'crop',
              crop: 'faces',
            })}
          />
        )}
      </Head>
      {preview && <PreviewModeButton />}
      {!page.settings?.splashPage && (
        <Breadcrumb breadcrumb={page.breadcrumb} hide={page?.items?.[0]?.type === 'banner'} />
      )}
      {/* <pre>{JSON.stringify(page, null, 2)}</pre> */}
      <div className="main-wrapper">
        {(pageItems || page.items || []).map((item) => {
          if (item.type === 'quicklinks') {
            return <Quicklinks key={item._id} {...item.params} />;
          }
          if (item.type === 'banner') {
            return <Banner key={item._id} {...item.params} />;
          }
          if (item.type === 'h1') {
            return <HeaderItem key={item._id} {...item.params} />;
          }
          if (item.type === 'h2') {
            return <HeaderItem key={item._id} {...item.params} />;
          }

          if (item.type === 'rte') {
            return <RichTextContent key={item._id} {...item.params} />;
          }
          if (item.type === 'products') {
            return <Products key={item._id} {...item.params} />;
          }
          if (item.type === 'instagram') {
            return <InstagramFeed key={item._id} {...item.params} />;
          }
          if (item.type === 'countdown') {
            return <CountdownItem key={item._id} {...item.params} />;
          }
          if (item.type === 'password') {
            return <PasswordProtectionItem key={item._id} {...item.params} updatePageWithPassword={updateWithPass} />;
          }
          if (item.type === 'buttons') {
            return <Buttons key={item._id} {...item.params} />;
          }
          if (item.type === 'html') {
            return <Html key={item._id} {...item.params} />;
          }
          if (item.type === 'images') {
            return <Images key={item._id} {...item.params} />;
          }
          if (item.type === 'videoEmbed') {
            return <VideoEmbed key={item._id} {...item.params} />;
          }
          if (item.type === 'splash-product') {
            return <SplashProduct key={item._id} {...item.params} />;
          }
          if (item.type === 'newsletter') {
            return <Newsletter key={item._id} {...item.params} />;
          }

          return <pre key={item._id}>{JSON.stringify(item, null, 2)}</pre>;
          // return null;
        })}
      </div>

      <PageStyles settings={page.settings} />
    </Layout>
  );
};

interface Params extends ParsedUrlQuery {
  slug1?: string;
  slug2?: string;
  slug3?: string;
}

type GetPagePropsType = (path: string, locale: Locales, cached: boolean) => Promise<FullPage>;

type GetInitialPropsType = (locale: Locales) => Promise<{ shop: ShopResponse; pages: SimplePage[] }>;
// This also gets called at build time
export const getStaticProps =
  (getInitialShop: GetInitialPropsType, getPage: GetPagePropsType): GetStaticProps<RegularPageProps, Params> =>
  async ({
    params,
    locale,
    preview,
    // previewData,
  }) => {
    const path = params
      ? ['', params.slug1, params.slug2, params.slug3].filter((slug) => slug !== undefined).join('/')
      : '/';

    const { pages, shop } = await getInitialShop(locale! as Locales);

    if (!path) {
      return { notFound: true };
    }

    const page = await getPage(path, locale! as Locales, !preview);
    // Pass post data to the page via props
    // console.log('🐶 getPage', page.slug, page._id);

    if (page._id === '__404__') {
      return { notFound: true };
    }

    return {
      props: {
        shop,
        pages,
        path,
        page: page,
        preview: preview || false,
      },
      revalidate: shop.devMode || shop.workingMode ? 1 : 60 * 30, // 30 minutes
    };
  };
