import styled from 'styled-components';
import Link from 'next/link';
import QuicklinkItem from './quicklinkItem';
import { getBoxStyles } from 'styles/styles';
import { isExternalLink } from 'utils/helpers';
import { PageItemQuicklinksParamsProps } from 'r2d2';

type StyledProps = {
  items: number;
};

export const Quicklinks: React.FC<PageItemQuicklinksParamsProps> = ({ items = [], cssClassName = '' }) =>
  items.length ? (
    <StyledQuickLinks
      className={`pageitem quicklinks row ${items.length < 4 ? 'has-few' : ''} ${cssClassName}`}
      items={items.filter((item) => item.image?.src).length}
    >
      {items
        .filter((item) => item.image?.src)
        .map((item) => (
          <div key={item._id} className={`quicklink `}>
            {isExternalLink(item.link || '') ? (
              <a href={item.link} target="_blank" rel="noopener noreferrer">
                <QuicklinkItem key={item._id} header={item.header} image={item.image} />
              </a>
            ) : (
              <Link prefetch={false} href={item.link}>
                <QuicklinkItem key={item._id} header={item.header} image={item.image} />
              </Link>
            )}
          </div>
        ))}
    </StyledQuickLinks>
  ) : null;

const StyledQuickLinks = styled.div<StyledProps>`
  padding: var(--side-padding);
  ${(props) => getBoxStyles(props.items)}
`;

export default Quicklinks;
