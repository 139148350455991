import NewsletterSignup from 'components/core/newsletter-signup';
import { PageItemNewsletterParamsProps } from 'r2d2';
import styled from 'styled-components';

export const Newsletter: React.FC<PageItemNewsletterParamsProps> = ({
  header = '',
  body = '',
  tags = [],
  cssClassName = '',
}) => {
  return (
    <StyledNewsletter className={`pageitem newsletter row ${cssClassName}`}>
      <div className="inner">
        {header && <h3>{header}</h3>}
        {body && <p>{body}</p>}

        <NewsletterSignup mode="newsletter" tags={tags} />
      </div>
    </StyledNewsletter>
  );
};

const StyledNewsletter = styled.div`
  padding: 0 var(--side-padding);
  display: flex;
  align-items: center;
  flex-direction: column;

  .inner {
    width: 100%;
    max-width: 500px;
  }
`;

export default Newsletter;
