import styled from 'styled-components';
import Image from 'next/legacy/image';
import { QuickLink } from 'r2d2';

export const QuicklinkItem: React.FC<Pick<QuickLink, 'header' | 'image'>> = ({ header, image }) => (
  <StyledQuicklinkItem>
    <Image
      className="image"
      src={image.src}
      width={image.width}
      height={image.height}
      layout="intrinsic"
      alt={image.alt || ''}
    />
    {header && <h3>{header}</h3>}
  </StyledQuicklinkItem>
);

const StyledQuicklinkItem = styled.div`
  padding: 0;
  /* border: 1px solid #eaeaea; */
  text-align: center;
  position: relative;
  /* transition: all 0.35s cubic-bezier(0.215, 0.61, 0.355, 1); */
  z-index: 9;

  overflow: hidden;

  h3 {
    position: absolute;
    bottom: 2rem;
    margin: 0;
    background: var(--primary-background);
    color: var(--primary-foreground);
    padding: 0.2rem 1rem;
    transition: all 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateX(-100%);
  }

  .image {
    transition: all 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  &:hover {
    z-index: 10;
    .image {
      transform: scale(1.05);
    }

    h3 {
      transform: translateX(0);
    }
  }
`;

export default QuicklinkItem;
