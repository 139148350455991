import React from 'react';
import styled from 'styled-components';

type EmbedProps = {
  url: string;
  width?: number;
  height?: number;
  autoplay?: boolean;
};

const Embed: React.FC<EmbedProps> = ({ url, width = 800, height = 450, autoplay = false }) => {
  const vimeoPattern = /(?:http?s?:\/\/)?(?:www\.|player\.)?(?:vimeo\.com)\/?(.+)/g;
  const youtubePattern =
    /(?:http?s?:\/\/)?(?:www\.)?(?:youtube\.com\/embed|youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/g;
  // const ytIdRegExp =
  //   /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  let embedCode = '';

  if (vimeoPattern.test(url)) {
    const replacement = `<iframe loading="lazy" width="${width}" height="${height}" src="//player.vimeo.com/$1?autoplay=${
      autoplay ? '1' : '0'
    }" frameborder="0" allow="autoplay; fullscreen"  webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>`;
    embedCode = url.replace(vimeoPattern, replacement);
  }

  const dimensionPadding = (height / width) * 100;

  // if (url.match(ytIdRegExp) && url.match(ytIdRegExp)[1]) {
  //   const youtubeId = url.match(ytIdRegExp)[1];
  //   const opts = {
  //     host: 'https://www.youtube-nocookie.com',
  //     height,
  //     width,
  //     playerVars: {
  //       // https://developers.google.com/youtube/player_parameters
  //       autoplay: autoplay ? 1 : 0,
  //     },
  //   };

  //   const onReady = (event) => {
  //     // console.log(event);
  //   };

  //   return (
  //     <EmbedStyled padding={dimensionPadding}>
  //       <h1>Helloo</h1>

  //       <YouTube videoId={youtubeId} opts={opts} onReady={onReady} />
  //     </EmbedStyled>
  //   );
  // }

  if (youtubePattern.test(url)) {
    //https://developers.google.com/youtube/player_parameters
    const embedUrl = url.replace(youtubePattern, '//www.youtube-nocookie.com/embed/$1');

    embedCode = `<iframe loading="lazy" width="${width}" height="${height}" src="${embedUrl.replace(
      '&',
      '?',
    )}?autoplay=${autoplay ? '1' : '0'}&rel=0&modestbranding=1" frameborder="0" allowfullscreen></iframe>`;
  }

  return <EmbedStyled padding={dimensionPadding} dangerouslySetInnerHTML={{ __html: embedCode }} />;
};

type EmbedStyleProps = {
  padding: number;
};

const EmbedStyled = styled.div<EmbedStyleProps>`
  width: 100%;
  position: relative;
  padding-bottom: ${(props) => props.padding}%;
  height: 0;
  overflow: hidden;
  margin: 1.5rem 0;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export default Embed;
