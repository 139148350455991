import { PageItemHeaderParamsProps } from 'r2d2';
import styled from 'styled-components';

const HeaderItem: React.FC<PageItemHeaderParamsProps> = ({ header, type, cssClassName = '' }) => {
  if (!header) {
    return null;
  }
  return (
    <StyledHeaderItem className={`pageitem header header-${type} ${cssClassName}`}>
      <div
        className="inner"
        dangerouslySetInnerHTML={{
          __html: `<${type} class="display">${header}</${type}>`,
        }}
      />
    </StyledHeaderItem>
  );
};
const StyledHeaderItem = styled.div`
  padding: 0 var(--side-padding);

  .inner {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default HeaderItem;
